"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-startup-common",
    class: {
      'kiwi-startup-common--closing': _vm.closing,
      'kiwi-startup-common--no-bg': !_vm.backgroundImage
    },
    style: _vm.styleCommon
  }, [_c('div', {
    staticClass: "kiwi-startup-common-section kiwi-startup-common-section-connection"
  }, [_vm._t("connection"), _vm._m(0), _vm._m(1)], 2)]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "startup-top"
  }, [_c('div', {
    staticClass: "startup-img"
  }, [_c('img', {
    staticClass: "startup-img",
    attrs: {
      "src": "https://hawke.uno/img/hone.gif",
      "alt": "huno"
    }
  })]), _c('div', {
    staticClass: "startup-title"
  }, [_vm._v(" hawke-uno 🍿 Community IRC Chat ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "startup-bottom"
  }, [_c('div', {
    staticClass: "kiwi-fontawesome-preload"
  }, [_c('i', {
    staticClass: "fa fa-spinner"
  })])]);
}];
exports.staticRenderFns = staticRenderFns;