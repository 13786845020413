"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('startup-layout', {
    ref: "layout",
    staticClass: "kiwi-welcome-simple",
    scopedSlots: _vm._u([_vm.startupOptions.altComponent ? {
      key: "connection",
      fn: function fn() {
        return [_c(_vm.startupOptions.altComponent, {
          tag: "component",
          on: {
            "close": _vm.onAltClose
          }
        })];
      },
      proxy: true
    } : null], null, true)
  });
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;