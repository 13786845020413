"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-mediaviewer"
  }, [_c('div', {
    staticClass: "kiwi-mediaviewer-controls"
  }, [_c('a', {
    staticClass: "u-button u-button-warning kiwi-mediaviewer-controls-close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-close",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('div', {
    staticClass: "kiwi-mediaviewer-content"
  }, [_vm.isIframe ? _c('iframe', {
    ref: "iframe",
    staticClass: "kiwi-mediaviewer-iframe",
    attrs: {
      "src": _vm.url,
      "sandbox": _vm.iframeSandboxOptions
    }
  }) : _vm.component ? _c(_vm.component, _vm._b({
    tag: "component",
    attrs: {
      "component-props": _vm.componentProps
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "setHeight": _vm.setHeight,
      "setMaxHeight": _vm.setMaxHeight
    }
  }, 'component', _vm.componentProps, false)) : _c('url-embed', {
    attrs: {
      "url": _vm.url,
      "show-pin": _vm.showPin,
      "iframe-sandbox-options": _vm.iframeSandboxOptions
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "setHeight": _vm.setHeight,
      "setMaxHeight": _vm.setMaxHeight
    }
  })], 1)]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;