"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-container",
    class: {
      /* 'kiwi-container-' + bufferType: true, */
      'kiwi-container--sidebar-drawn': _vm.sidebarState.isDrawn,
      'kiwi-container--sidebar-open': _vm.sidebarState.isOpen,
      'kiwi-container--no-sidebar': _vm.buffer && _vm.buffer.isSpecial
    }
  }, [_vm.buffer ? [_c('container-header', {
    attrs: {
      "buffer": _vm.buffer,
      "sidebar-state": _vm.sidebarState
    }
  }), _vm._t("before"), _vm.buffer.getNetwork().state !== 'connected' && !_vm.buffer.isServer() ? _c('not-connected', {
    attrs: {
      "buffer": _vm.buffer,
      "network": _vm.buffer.getNetwork()
    }
  }) : _vm._e(), _c('div', {
    staticClass: "kiwi-container-content"
  }, [_vm.buffer.isServer() ? [_c('server-view', {
    attrs: {
      "network": _vm.network
    }
  })] : [_c('message-list', {
    attrs: {
      "buffer": _vm.buffer
    }
  })], _vm._t("after")], 2)] : [_c('div', {
    staticClass: "kiwi-container-empty"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t('container_welcome')))]), _c('a', {
    staticClass: "u-button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleStateBrowser.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('container_statebrowser')) + " ")])])]], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;