"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-notconnected kiwi-warning-block",
    class: {
      connecting: _vm.shouldShowLoading
    }
  }, [!_vm.shouldShowLoading ? [_c('div', {
    staticClass: "kiwi-notconnected-caption"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('not_connected')))]), _vm._v(" Refresh page to reconnect. ")])] : _c('div', {
    staticClass: "kiwi-notconnected-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t('connecting')) + " "), _c('i', {
    staticClass: "fa fa-refresh fa-spin kiwi-notconnected-bigicon",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('captcha', {
    staticClass: "kiwi-notconnected-captcha",
    attrs: {
      "network": _vm.network
    }
  })], 2);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;