"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-header kiwi-theme-bg",
    on: {
      "click": _vm.onHeaderClick
    }
  }, [_vm.isChannel() ? [_vm._m(0), _vm._m(1), !_vm.isJoined && _vm.isConnected ? _c('div', {
    staticClass: "kiwi-header-notjoined"
  }, [_c('a', {
    staticClass: "u-link kiwi-header-join-channel-button",
    on: {
      "click": _vm.joinCurrentBuffer
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('container_join')) + " ")])]) : _vm._e()] : _vm.isServer() ? [_c('div', {
    staticClass: "kiwi-header-name-container"
  }, [_c('div', {
    staticClass: "kiwi-header-name"
  }, [_c('div', {
    staticClass: "kiwi-header-name-full"
  }, [_vm._v(_vm._s(_vm.buffer.getNetwork().name))]), _vm._v(" " + _vm._s(_vm.buffer.getNetwork().name) + " ")])]), _c('div', {
    staticClass: "kiwi-header-center"
  }), _c('div', {
    staticClass: "kiwi-header-server-connection"
  }, [_vm.buffer.getNetwork().state === 'disconnected' ? _c('a', {
    staticClass: "u-button u-button-primary",
    on: {
      "click": _vm.onConnectButtonClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('connect')) + " ")]) : _vm.buffer.getNetwork().state === 'connecting' ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('connecting')) + " ")]) : _vm._e()])] : _vm.isQuery() ? [_c('div', {
    staticClass: "kiwi-header-name-container"
  }, [_c('div', {
    staticClass: "kiwi-header-name"
  }, [_c('div', {
    staticClass: "kiwi-header-name-full"
  }, [_c('away-status-indicator', {
    staticClass: "kiwi-header-awaystatus",
    attrs: {
      "network": _vm.buffer.getNetwork(),
      "user": _vm.network.userByName(_vm.buffer.name)
    }
  }), _vm._v(_vm._s(_vm.buffer.name) + " ")], 1), _c('away-status-indicator', {
    staticClass: "kiwi-header-awaystatus",
    attrs: {
      "network": _vm.buffer.getNetwork(),
      "user": _vm.network.userByName(_vm.buffer.name)
    }
  }), _vm._v(_vm._s(_vm.buffer.name) + " ")], 1)]), _c('div', {
    staticClass: "kiwi-header-center"
  })] : _vm.isSpecial() ? [_c('div', {
    staticClass: "kiwi-header-name-container"
  }, [_c('div', {
    staticClass: "kiwi-header-name"
  }, [_c('div', {
    staticClass: "kiwi-header-name-full"
  }, [_vm._v(_vm._s(_vm.buffer.name))]), _vm._v(" " + _vm._s(_vm.buffer.name) + " ")])]), _c('div', {
    staticClass: "kiwi-header-center"
  })] : _vm._e()], 2);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-header-name-container"
  }, [_c('div', {
    staticClass: "kiwi-header-name"
  }, [_c('div', {
    staticClass: "kiwi-header-img"
  }, [_c('img', {
    staticClass: "kiwi-header-img",
    attrs: {
      "src": "https://hawke.uno/img/hone.gif",
      "alt": "huno"
    }
  })]), _c('div', {
    staticClass: "kiwi-header-title"
  }, [_vm._v(" hawke-uno 🍿 Community IRC Chat ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-header-center"
  }, [_c('div', {
    staticClass: "kiwi-header-topic"
  }, [_vm._v(" #huno »« #basement | tracker banter ")])]);
}];
exports.staticRenderFns = staticRenderFns;